import React from 'react';

export const About = () => {
    return (
        <React.Fragment>
            <h2>
                Is Reinforcement Learning (Not) for Natural Language Processing?: Benchmarks,
                Baselines, and Building Blocks for Natural Language Policy Optimization
            </h2>
            <table>
                <tr>
                    <td width="300px">
                        <a
                            href="https://scholar.google.de/citations?user=vVzcztcAAAAJ&hl=en"
                            target="_blank"
                            rel="noreferrer">
                            Rajkumar Ramamurthy*
                        </a>
                    </td>
                    <td width="300px">
                        <a href="http://prithvirajva.com/" target="_blank" rel="noreferrer">
                            Prithviraj Ammanabrolu*
                        </a>
                    </td>
                    <td width="300px">
                        <a href="http://www.cs.umd.edu/~kdbrant/" target="_blank" rel="noreferrer">
                            Kianté Brantley
                        </a>
                    </td>
                    <td width="300px">
                        <a href="https://jmhessel.com/" target="_blank" rel="noreferrer">
                            Jack Hessel
                        </a>
                    </td>
                </tr>{' '}
                <br />
                <tr>
                    <td width="200px">
                        <a
                            href="https://sites.google.com/view/rafetsifa"
                            target="_blank"
                            rel="noreferrer">
                            Rafet Sifa
                        </a>
                    </td>
                    <td width="200px">
                        <a
                            href="https://www.iais.fraunhofer.de/de/institut/mitarbeiterprofile/christian-bauckhage.html"
                            target="_blank"
                            rel="noreferrer">
                            Christian Bauckhage
                        </a>
                    </td>
                    <td width="200px">
                        <a
                            href="https://homes.cs.washington.edu/~hannaneh/"
                            target="_blank"
                            rel="noreferrer">
                            Hannaneh Hajishirzi
                        </a>
                    </td>
                    <td width="200px">
                        <a
                            href="https://homes.cs.washington.edu/~yejin/"
                            target="_blank"
                            rel="noreferrer">
                            Yejin Choi
                        </a>
                    </td>
                </tr>
            </table>
            <br />
            <br />
            <div style={{ textAlign: 'justify' }}>
                <img
                    style={{
                        float: 'right',
                        width: '50%',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        paddingBottom: '0px',
                    }}
                    src="/grue.gif"></img>
                <p>
                    {' '}
                    We tackle the problem of training large language models to align to measures of
                    human preferences. We note that many Natural Language Processing (NLP) tasks can
                    be framed as sequence learning problems, with multitudes of non-differentiable
                    automated metrics designed to grade performance by mimicking human judgements
                    for the task.
                </p>
                <p>
                    {' '}
                    Reinforcement Learning (RL) is a powerful paradigm for solving sequential tasks
                    that learn from such scalar feedback and yet the use of RL for NLP tasks is
                    severely hindered by a series of important, yet often undocumented pitfalls
                    including: training instability of RL algorithms with combinatorially-sized
                    language action spaces; high variance in automated NLP metrics to be used for
                    feedback in the form of rewards; and reward hacking where a metric is
                    state-of-the-art but the underlying spirit of the task remains unsolved.
                </p>
                <p>The RL4LMs project attempts to alleviate these pitfalls by:</p>
                <p>
                    {' '}
                    (1) providing guidelines for when RL should be used and what kinds of current
                    NLP tasks and metrics are best suited for it in the form of a new ever-evolving
                    benchmark dubbed GRUE (General Reinforced-language Understanding Evaluation);
                </p>
                <p>
                    {' '}
                    (2) demonstrating how to use RL for language via a novel RL algorithm NLPO
                    (Natural Language Policy Optimization) created to be more stable and less
                    susceptible to both large language action spaces and high variance in rewards;{' '}
                </p>
                <p>
                    {' '}
                    (3) A practical day-to-day guide including high-quality implementations and
                    hyperparameters of NLPO along with multiple existing online RL algorithms such
                    as PPO and A2C to train any causal or seq2seq transformer in the popular
                    HuggingFace library.{' '}
                </p>
            </div>
        </React.Fragment>
    );
};
