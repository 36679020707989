import React from 'react';

import { Layout, Menu, Card } from 'antd';

import data from './tasks.json';

const { Sider } = Layout;

const styles = {
    sider: {
        overflow: 'auto',
        maxHeight: '75vh',
    },
    card: {
        maxHeight: '75vh',
    },
    cardHead: {
        maxHeight: '10%',
        height: '10%',
    },
    cardBody: {
        maxHeight: '90%',
        height: '90%',
        overflow: 'auto',
    },
};

function titleCase(str) {
    str = str.replaceAll('-', ' ');
    return str
        .toLowerCase()
        .split(' ')
        .map(function (word) {
            return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(' ');
}

// Sort tasks by their name.
data.sort(function (a, b) {
    return a.taskName.localeCompare(b.taskName, undefined, { numeric: true, sensitivity: 'base' });
});

export class Explore extends React.Component {
    state = {
        selected: data[0],
    };

    onClick = ({ key }) => {
        this.setState({ selected: data[parseInt(key)] });
    };

    render() {
        return (
            <React.Fragment>
                <h2>GRUE (General Reinforced-Language Understanding Evaluation)</h2>
                <br />
                <br />
                <div style={{ textAlign: 'justify' }}>
                    <p>
                        {' '}
                        GRUE is a benchmark that has a collection of 6 generative NLP tasks. We
                        leverage the RL4LM framework to study these 6 tasks using RL. We train and
                        evaluate each RL algorithm used in the study on two categories of metrics:
                        Task-specific metrics and Nerutalness metrics. Task-specific metrics capture
                        the performance of a particular generation task, whereas Naturalness
                        captures non-task-specific performance such as fluency and readability. We
                        compare 5 fine-tuning algorithms --- Supervised, PPO, NLPO, Supervised+PPO,
                        and Supervised+NLPO. The latter two algorithms first form Supervised
                        fine-tuning and then fine-tuning using the respective RL algorithm.
                    </p>

                    <p>
                        We gather several key insights from the benchmark when training RL for
                        LM-Based generation:
                    </p>
                    <p>
                        {' '}
                        (1) Using Supervised+RL works best compared to only using RL or supervised
                        learning. The initial supervised fine-tuning is important because it is also
                        used for the KL constraint. The KL constraint is important because it
                        prevents reward hacking.
                    </p>
                    <p>
                        {' '}
                        (2) We notice that when a fixed data collection budget, learning a reward
                        function for RL using the data performs better than using the data for
                        supervised learning. This implies that reward modeling is more
                        data-efficient than supervised learning.
                    </p>
                    <p>
                        {' '}
                        (3) We notice that RL for LM-based training is sensitive to the discount
                        factor gamma, using dropout as a regularization and the sampling method used
                        during exploration and inference.{' '}
                    </p>
                    <p>Below we provided generation outputs for all 6 tasks.</p>
                </div>
                <Layout>
                    <Sider style={styles.sider}>
                        <div className="logo" />
                        <Menu
                            theme="dark"
                            defaultSelectedKeys={['0']}
                            mode="inline"
                            onClick={this.onClick}>
                            {data &&
                                data.length > 0 &&
                                data.map((item, idx) => (
                                    <Menu.Item key={idx} title={item.taskName}>
                                        {item.taskName}
                                    </Menu.Item>
                                ))}
                        </Menu>
                    </Sider>
                    <Layout>
                        <Card
                            title={`GRUE - ${titleCase(this.state.selected.taskName)}`}
                            style={styles.card}
                            headStyle={styles.cardHead}
                            bodyStyle={styles.cardBody}>
                            <code
                                id="textgame"
                                style={{
                                    wordBreak: 'break-word',
                                    textAlign: 'left',
                                    backgroundColor: 'white',
                                    border: 'none',
                                    padding: '0',
                                    whiteSpace: 'normal',
                                }}>
                                <strong>Task Description:</strong>{' '}
                                {this.state.selected.taskDescription} <br />
                                {this.state.selected.path.map((step) => (
                                    <>
                                        <br /> <strong>{step.action}</strong>
                                        <br />
                                        <span style={{ whiteSpace: 'pre-wrap' }}>
                                            {step.observation} <br />
                                        </span>
                                        {step.isCompleted === 'true' ? (
                                            <strong style={{ color: 'darkgreen' }}>
                                                {' '}
                                                (Sample Completed) <br />
                                            </strong>
                                        ) : (
                                            '\n'
                                        )}
                                    </>
                                ))}
                            </code>
                        </Card>
                    </Layout>
                </Layout>
            </React.Fragment>
        );
    }
}
